@font-face {
    font-family: 'benedenboven';
    src: url('../fonts/benedenboven-black.eot');
    src: url('../fonts/benedenboven-black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/benedenboven-black.woff') format('woff'),
    url('../fonts/benedenboven-black.ttf') format('truetype'),
    url('../fonts/benedenboven-black.svg#benedenboven') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'benedenboven-light';
    src: url('../fonts/benedenboven-light.eot');
    src: url('../fonts/benedenboven-light?#iefix') format('embedded-opentype'),
    url('../fonts/benedenboven-light.woff') format('woff'),
    url('../fonts/benedenboven-light.ttf') format('truetype'),
    url('../fonts/benedenboven-light.svg#benedenboven-light') format('svg');
    font-weight: 300;
    font-style: normal;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    border-style: none;
}

img:not([src]) {
    content: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: @body-font;
    background-color: @bg-secondary;
    color: #111111;
    font-size: 17px;
    line-height: 1.8;
    font-weight: 400;
    overflow-x: hidden;
}

.filepond--label-action {
    color: @color-primary !important;
}

::selection {
    background: @color-primary; /* WebKit/Blink Browsers */
    color: @color-white;
    opacity: 1;
}

::-moz-selection {
    background: @color-primary; /* Gecko Browsers */
    color: @color-white;
    opacity: 1;
}

@media (max-width: 767px) {
    .container {
        max-width: 767px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 630px;
    }
}

@media (min-width: 800px) {
    .container {
        max-width: 660px;
    }
}

@media (min-width: 900px) {
    .container {
        max-width: 760px;
    }
}

@media (min-width: 1000px) {
    .container {
        max-width: 860px;
    }
}

@media (min-width: 1100px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1060px;
    }
}

@media (min-width: 1300px) {
    .container {
        max-width: 1160px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1260px;
    }
}

@media (min-width: 1500px) {
    .container {
        max-width: 1360px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1460px;
    }
}

@media (min-width: 1700px) {
    .container {
        max-width: 1560px;
    }
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.wow {
    visibility: hidden;
}

.logo {
    position: fixed;
    z-index: 999999999999;
    left: 20px;
    top: 20px;
}

/*!---------- MASONRY ----------*/
.grid-masonry:after {
    content: '';
    display: block;
    clear: both;
}

.grid-masonry-sizer,
.grid-masonry-item {
    width: 33%;
    padding: 32px;
}

.grid-masonry-item {
    min-height: 80px;
    height: auto;
    float: left;
    border: 8px solid @bg-secondary;
    text-align: center;
    overflow: hidden;
}

.grid-masonry-item h2 {
    font-size: 30px;
    text-transform: uppercase;
    color: @color-white;
}

.grid-masonry-item.bg-dark,
.grid-masonry-item.grid-masonry-item-img {
    color: @color-white;
}

.grid-masonry-item.bg-primary,
.grid-masonry-item.bg-white h2 {
    color: @bg-dark;
}

.grid-masonry-item-img {
    background-position: center;
    background-size: cover;
    font-family: @body-font;
    text-align: center;
}

.grid-masonry-item-img:before {
    .overlay-params(.8, @bg-dark)
}

.grid-masonry-item-img-content {
    position: relative;
    z-index: 10;
}

.grid-masonry-item img {
    display: block;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 32px;
}

@media all and (max-width: 1500px) {
    .grid-masonry-sizer,
    .grid-masonry-item {
        width: 33%;
    }
}

@media all and (max-width: 990px) {
    .grid-masonry-sizer,
    .grid-masonry-item {
        width: 50%;
    }
}

@media all and (max-width: 768px) {
    .grid-masonry-sizer,
    .grid-masonry-item {
        width: 100%;
    }
}

/*!---------- MIXINS ----------*/

.transition(@time) {
    transition: all @time ease;
    -webkit-transition: all @time ease;
    -moz-transition: all @time ease;
}

.transition-property(@property,@time) {
    transition: @property @time ease;
    -webkit-transition: @property @time ease;
    -moz-transition: @property @time ease;
}

.transition-property-delay(@property,@time,@delay) {
    transition: @property @time ease;
    -webkit-transition: @property @time ease;
    -moz-transition: @property @time ease;
    transition-delay: @delay;
    -webkit-transition: @delay;
}

.translateX(@amount) {
    transform: translate3d(@amount, 0, 0);
    -webkit-transform: translate3d(@amount, 0, 0);
    -moz-transform: translate3d(@amount, 0, 0);
}

.translateY(@amount) {
    transform: translate3d(0, @amount, 0);
    -webkit-transform: translate3d(0, @amount, 0);
    -moz-transform: translate3d(0, @amount, 0);
}

.v-align-children {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.scale(@amount) {
    transform: scale(@amount);
    -webkit-transform: scale(@amount);
}

.v-align-children-column {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.disable-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-align-transform {
    position: relative;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    top: 50%;
}

.v-align-bottom {
    position: relative;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    top: 100%;
    z-index: 2;
}

.overlay-params(@opacity,@color) {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: @color;
    opacity: @opacity;
    z-index: 2;
}

.heading-colors(@color) {
    h1, h2, h3, h4, h5, h6 {
        color: @color;
    }
}

.text-colors(@color) {
    p, span, li {
        color: @color;
    }
}

.box-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.box-shadow-xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
}

.delay {
    -animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
}

/*!---------- 3. TYPOGRAPHY ----------*/

@media all and (max-width: 767px) {
    .text-center-xs {
        text-align: center !important;
    }
}

@font-awesome: "Font Awesome 5 Free";
@body-font: 'Overpass', sans-serif;
@heading-font: 'benedenboven';
.bold {
    font-weight: bolder;
}

.thin {
    font-family: 'benedenboven-light';
}

h1, h2, h3, h4, h5, h6, p, ol, pre, table, blockquote, input, button, select, textarea {
    margin-bottom: 24px;
    margin-top: 0;
    padding: 0;
}

ul {
    padding-left: 32px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: @heading-font;
}

h1, h2, h3, h4, h5, h6 {
    color: @color-body;
}


h1, .h1 {
    font-size: 64px;
    line-height: 72px;
}

h1.large {
    font-size: 90px;
    line-height: 80px;
}

@media all and (max-width: 1600px) {
    h1.large {
        font-size: 80px;
        line-height: 70px;
    }
}

@media all and (max-width: 1400px) {
    h1.large {
        font-size: 70px;
        line-height: 70px;
    }
}

@media all and (max-width: 1300px) {
    h1.large {
        font-size: 60px;
        line-height: 60px;
    }
}

@media all and (max-width: 1200px) {
    h1.large {
        font-size: 50px;
        line-height: 50px;
    }
}

@media (max-width: 990px) and (min-width: 700px) {
    h1.large {
        font-size: 80px !important;
        line-height: 80px !important;
    }
}

h1.xlarge {
    font-size: 7.5em;
    line-height: .8;
}

h1.contact {
    font-size: 78px;
    line-height: 84px;
}

@media all and (max-width: 1300px) {
    h1.xlarge {
        font-size: 6em;
        line-height: .8;
    }
}

@media all and (max-width: 990px) {
    h1.large {
        font-size: 42px;
        line-height: 44px;
    }

    h1.xlarge {
        font-size: 4em;
        line-height: .8;
    }
}

h2 {
    font-size: 36px;
    line-height: 42px;
}

h3 {
    font-size: 32px;
    line-height: 40px;
}

h4 {
    font-size: 24px;
    line-height: 32px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6, .h6 {
    font-size: 12px;
    line-height: 24px;
}

@media all and (max-width: 767px) {
    h1, h1.large, .h1 {
        font-size: 46px;
        line-height: 54px;
    }

    h2 {
        font-size: 32px;
        line-height: 40px;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
    }

    h4 {
        font-size: 18px;
        line-height: 26px;
    }

    h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    h6, .h6 {
        font-size: 12px;
        line-height: 24px;
        font-weight: 700;
    }
}

.uppercase {
    text-transform: uppercase;
}

h6.uppercase, .h6-u {
    font-weight: 700;
}

.bold-h6 {
    .h6;
    .uppercase;
    .h6-u;
}

p.lead {
    font-size: 18px;
    line-height: 32px;
}

.title {
    max-width: 400px;
}

.quote {
    font-size: 64px;
    line-height: 74px;
    font-family: @heading-font;
}

@media all and (max-width: 767px) {
    p {
        font-size: 16px;
        line-height: 26px;
    }

    p.lead {
        font-size: 18px;
        line-height: 28px;
    }

    .quote {
        font-size: 54px;
    }
}

a, a:visited, a:focus, a:active, a:hover {
    text-decoration: none;
    outline: none;
    color: @color-body;
}

a {
    color: @color-primary;
    .transition(0.3s);
    cursor: pointer;
}

.image-bg a, .bg-primary a {
    color: #fff;
}

.image-bg a:hover, .bg-primary a:hover {
    color: #fff;
}

.bg-light a {
    color: @color-primary;
}

.bg-light a:hover {
    color: @color-primary;
    opacity: 1;
}

a:hover {
    color: darken(@color-primary, 10%);
    text-decoration: none;
}

.label {
    text-transform: uppercase;
    .bold-h6;
    letter-spacing: 1px;
    background: @color-primary;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 10px;
    vertical-align: middle;
}

.text-left {
    text-align: left;
}

.sub {
    font-size: 16px;
    line-height: 28px;
    font-family: @heading-font;
    .thin
}

.heading-fade {
    color: #2B111B;
}

@media all and (max-width: 767px) {
    .text-left-xs {
        text-align: left !important;
    }
}

/*!---------- COLOURS ----------*/
@bb: #E91E63;
@bg-primary: @bb;
@bg-secondary: #f5f5f5;
@bg-dark: #000;
@color-dark: #000;
@color-white: #fff;
@color-body: #000;
@color-gray: #A0A0A0;
@color-primary: @bb;
@color-secondary: #2B111B;
@dark-pink: #8f2e4e;
@heading-color-dark: #000;
.heading-color-dark {
    color: @heading-color-dark !important;
}

.heading-color {
    color: #A0A0A0 !important;
}

.bg-primary {
    background: @bb !important;
}

.bg-secondary {
    background: @bg-secondary !important;
}

.bg-white {
    background: #fff;
}

.bg-dark {
    background-color: @bg-dark !important;
}


.bg-shadow {
    background: linear-gradient(@color-white, @color-white 55%, @bg-secondary 60%);
}

.color-body {
    color: #000 !important;
}

.color-primary {
    color: @bb !important;
}

.color-white {
    color: #fff !important;
}

.color-gray {
    color: @color-gray !important;
}


/*!----------  SECTIONS ----------*/
section, footer {
    padding: 96px 0;
    position: relative;
    background: @bg-secondary;
}

.fullscreen {
    height: 100vh;
}

.fullscreen-80 {
    height: 80vh;
}

.bg-dark, nav.bg-dark, footer.bg-dark, .bg-primary {
    .heading-colors(#fff);
    .text-colors(#fefefe);
}


@media all and (max-width: 767px) {
    section {
        padding: 80px 0;
    }
}

.overlay:before {
    .overlay-params(.3, @bg-dark);
}

.overlay-gradient:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 70%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(bottom, rgba(0, 39, 118, 1) 0%, rgba(0, 39, 118, 0) 100%);
    background: -webkit-linear-gradient(bottom, rgba(0, 39, 118, 1) 0%, rgba(0, 39, 118, 0) 100%);
    background: linear-gradient(to top, rgba(0, 39, 118, 1) 0%, rgba(0, 39, 118, 0) 100%);
    opacity: .8;
    z-index: 2;
}

.overlay-gradient-walloffame:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 70%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
    z-index: 2;
}

.overlay-gradient-case-detail:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0) 50%);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0) 50%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0) 50%);
    opacity: 1;
    z-index: 3;
}


.overlay-heavy:before {
    .overlay-params(.6, @bg-dark);
}

.bg-light.overlay:before {
    .overlay-params(.1, #fff);
}


.image-bg {
    .heading-colors(@color-white);
    .text-colors(@color-white);
}

.image-bg .container,
.image-bg div[class*='col-'] {
    position: relative;
    z-index: 3;
}

.container.image-bg .row {
    position: relative;
    z-index: 3;
}

.background-image-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
    background: @bg-dark;
    background-size: cover !important;
    background-position: 50% 50% !important;
    .transition(0.3s);
    opacity: 0;
}

.background-image-holder img {
    display: none;
}

.background-image-holder.fadeIn {
    opacity: 1;
}

/*!---------- HEADER VIDEO WITH MASK ----------*/

video::-internal-media-controls-overlay-cast-button {
    display: none;
}

.header-video {
    height: 100%;
}

.header-video video {
    margin-right: -30%;
    margin-top: 0px;
    width: 130%;
    transform: translateY(-1px);
    -webkit-mask-image: url(/img/bb.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-color: @color-primary;
    background-color: @color-primary;
}

.narrowcasting-video {
    height: 100%;
}

.narrowcasting-video video {
    margin-right: -50%;
    margin-top: 0px;
    width: 150%;
    -webkit-mask-image: url(/img/narrowcasting-icon.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-color: @color-primary;
    background-color: @color-primary;
}

.narrowcasting-slide {
    background-color: lighten(@color-gray, 50%);
    padding: 10px;
    border: 10px solid #f5f5f5;

    h5 {
        padding: 0;
        margin: 10px 0;
        color: @color-primary;
    }

    p {
        font-size: 10px;
        padding: 0;
        margin: 0;
    }
}

.narrowcasting-float {
    position: absolute;
    right: 50px;
    top: 300px;
    z-index: 99;
}

@media all and (max-width: 1600px) {
    .narrowcasting-float {
        top: 330px;
    }
}

@media all and (max-width: 1400px) {
    .narrowcasting-float {
        top: 400px;
    }
}

@media all and (max-width: 1000px) {
    .narrowcasting-float {
        display: none;
    }
}

.overflow-visible {
    overflow: visible !important;
}

.narrowcasting-shadow {
    -webkit-filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.3));
}

@media all and (max-width: 990px) {
    .header-video video {
        margin-top: 0;
        width: 100%;
        height: auto;
    }

    .narrowcasting-video video {
        margin-top: 0;
        width: 100%;
        height: auto;
    }

    .wofm {
        background: url(' /img/ walloffame . jpg ');
        background-size: cover;
        background-position: center;
    }
}

.example {
    position: absolute;
    right: -50px;
    margin: 0 auto;

    img {
        height: 70px;
        width: auto;
    }
}

.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}


.header-wof {
    width: 100%;
}

.header-wof video {
    margin-right: -15%;
    margin-top: 0px;
    width: 115%;
    -webkit-mask-image: url(/img/waf.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-color: @color-primary;
    background-color: @color-primary;
}

@media all and (max-width: 990px) {
    .header-wof video {
        margin-top: 0;
        width: 100%;
        height: auto;
    }
}

/*!---------- Animation ball ----------*/

#ground {
    position: absolute;
    background: #ad8766;
    top: 18em;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

#shadow {
    position: absolute;
    top: 19em;
    left: 50%;
    margin-left: -1em;
    width: 2em;
    height: 1em;
    background: #111;
    border-radius: 50%;
    -webkit-animation: pulse .5s infinite alternate;
    -moz-animation: pulse .5s infinite alternate;
    -o-animation: pulse .5s infinite alternate;
    animation: pulse .5s infinite alternate;
}

@-webkit-keyframes pulse {
    0% {
        width: 2em;
        margin-left: -1em;
    }
    75% {
        width: 2.5em;
        margin-left: -1.25em;
    }
    100% {
        width: 3em;
        margin-left: -1.5em;
    }
}

@-webkit-keyframes pulse {
    0% {
        width: 2em;
        margin-left: -1em;
    }
    75% {
        width: 2.5em;
        margin-left: -1.25em;
    }
    100% {
        width: 3em;
        margin-left: -1.5em;
    }
}

@-moz-keyframes pulse {
    0% {
        width: 2em;
        margin-left: -1em;
    }
    75% {
        width: 2.5em;
        margin-left: -1.25em;
    }
    100% {
        width: 3em;
        margin-left: -1.5em;
    }
}

@-o-keyframes pulse {
    0% {
        width: 2em;
        margin-left: -1em;
    }
    75% {
        width: 2.5em;
        margin-left: -1.25em;
    }
    100% {
        width: 3em;
        margin-left: -1.5em;
    }
}

@keyframes pulse {
    0% {
        width: 2em;
        margin-left: -1em;
    }
    75% {
        width: 2.5em;
        margin-left: -1.25em;
    }
    100% {
        width: 3em;
        margin-left: -1.5em;
    }
}

#ball {
    position: relative;
    background: #f7f7f7;
    top: 3em;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 2;
    -webkit-animation: bounce .5s cubic-bezier(.63, .09, .75, .46) infinite alternate;
    -moz-animation: bounce .5s cubic-bezier(.63, .09, .75, .46) infinite alternate;
    -o-animation: bounce .5s cubic-bezier(.63, .09, .75, .46) infinite alternate;
    animation: bounce .5s cubic-bezier(.63, .09, .75, .46) infinite alternate;
}

@keyframes bounce {
    0%, 10% {
        top: 3em;
        height: 3em;
        width: 3em;
    }
    15% {
        height: 3.15em;
        width: 2.9em;
    }
    35% {
        height: 4em;
        width: 2.5em;
    }
    75% {
        height: 4em;
        width: 2.5em;
    }
    95% {
        height: 2em;
        width: 3.5em;
    }
    100% {
        top: 180em;
        height: 1em;
        width: 3em;
    }
}

/*!---------- 404 ----------*/

.controls {
    max-height: 50px;
    width: auto;
}

.text-blink {
    margin: 0 auto 15px 0;
    text-align: center;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

/*!---------- NAVIGATION ----------*/
nav {
    position: absolute;
}

.badge-primary {
    background: @color-primary;
}

.menu-container {
    z-index: 99;
    position: fixed;
    display: flex;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
}

.menu {
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    transition-delay: 0.5s;
    visibility: hidden;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin-top: -124px;
}

.menu-left ul li {
    font-family: @heading-font;
    font-weight: 100;
    font-size: 60px;
    height: 72px;
    color: #fff;
}

.menu ul li a {
    cursor: pointer;
    color: #fff;
}

.menu ul li a:hover {
    color: @color-primary;
    margin-left: 5px;
}

.menu-right ul li {
    color: #fff;
}

.menu-right i {
    margin-left: 10px;
}

.menu-right {
    text-align: right;
}

.menu-logo a svg {
    height: 100px;
}

.menu-sliders {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: flex 0.45s;
}

.menu-sliders:first-child {
    background: #000;
}

.menu-sliders:nth-child(2) {
    background: #000;
}

.menu-sliders:nth-child(3) {
    flex: 1 0 100%;
    background: transparent;
}

.plus-btn-pos {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 101;
}

.plus-btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: white;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.plus-btn div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 3px;
    background: @color-primary;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    transition-delay: 0.5s;
}

.plus-btn .r1 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.plus-btn .r2 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-180deg);
    transform: translateX(-50%) translateY(-50%) rotate(-180deg);
}

.plus-btn:hover {
    background: @color-primary;
}

.plus-btn:hover div {
    background: white;
}

.plus-btn:active {
    box-shadow: none;
}

body.menu-open .menu-sliders:nth-child(3) {
    flex: 0 0 0%;
}

body.menu-open .menu {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    pointer-events: all;
}

body.menu-open .plus-btn .r1 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

body.menu-open .plus-btn .r2 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-135deg);
    transform: translateX(-50%) translateY(-50%) rotate(-135deg);
}

@media (max-width: 990px) {
    .menu-right {
        font-size: 14px;
    }

    .menu-left ul li {
        font-size: 36px;
        height: 46px;
        color: #fff;
    }

    .menu-logo svg {
        height: 70px;
    }
}

@media (max-width: 768px) {
    .menu-right {
        visibility: hidden;
        opacity: 0;
    }
}


/*!---------- INTROS / PAGE TITLES ----------*/
#gl {
    position: absolute;
    width: 100%;
    height: 100%;;
    left: 0;
    top: 0;
}

.gl-frame {
    text-align: center;
    position: relative;
    z-index: 2;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.gl-frame-title {
    position: relative;
    line-height: 0.8;
    pointer-events: none;
    font-size: 3vw;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: @color-white;
    font-family: @heading-font;
    .v-align-transform;
    z-index: 99;
    top: 70%;
}

.gl-frame-sub-title {
    position: relative;
    line-height: 0.8;
    pointer-events: none;
    font-size: 0.8vw;
    text-align: center;
    font-family: @body-font;
    color: @color-white;
    .v-align-transform;
    z-index: 99;
    top: 70%;
}

.gl-frame-container {
    position: relative;
    height: 100%;
    margin-top: -110px;
}

.gl-frame-info {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: left;
    color: @color-white;
    z-index: 99 !important;

    div {
        color: #fff;

        h3 {
            color: #fff;
        }

        img.img-fluid {
            .v-align-transform;
            height: 70px;
            position: relative;
        }
    }
}

.gl-frame-popup {
    position: absolute;
    top: -60px;
}

.button {
    width: 30px;
    height: 30px;
    display: block;
}

.bounce {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
    }
}

@-o-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -o-transform: translateY(0);
    }
    40% {
        -o-transform: translateY(-30px);
    }
    60% {
        -o-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    overflow: hiden;
    transition: .64s ease-in-out;
}

.popup-inner {
    position: relative;
    bottom: -100vw;
    right: -100vh;
    display: flex;
    align-items: center;
    max-width: 800px;
    max-height: 600px;
    width: 60%;
    height: 80%;
    background-color: #fff;
    -webkit-transform: rotate(32deg);
    transform: rotate(32deg);
    transition: .64s ease-in-out;
}

.popup__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    overflow: hidden;
}

.popup__photo img {
    width: auto;
    height: 100%;
}

.popup__text {
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 100%;
    padding: 4rem;
}

.popup__text h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: #0A0A0A;
}

.popup__text p {
    font-size: .875rem;
    color: #686868;
    line-height: 1.5;
}

.popup:target {
    visibility: visible;
    opacity: 1;
}

.popup:target .popup-inner {
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.popup__close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    font-weight: 300;
    border-radius: 100%;
    background-color: #E91E63;
    color: #fff !important;
    font-family: @heading-font;
    z-index: 4;
    color: #fff;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}


.page-title {
    height: 650px;
    width: 100%;
    left: 0;
    top: 0;

    .gl-frame-title {
        text-align: center;
    }
}

.page-title-sub {
    height: 550px;
}

.page-title .container {
    z-index: 5;
}

.page-title .container .large {
    mix-blend-mode: luminosity;
}

@media (max-width: 768px) {
    .page-title {
        height: 500px;
    }
}

@media (max-width: 990px) and (min-width: 769px) {
    .page-title {
        height: 500px;
    }
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
}

/* ----------- Animated titles ------------ */
.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

.cd-headline.loading-bar span {
    display: inline-block;
}

.cd-headline.loading-bar .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
    /* loading bar */
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 0;
    background: @color-primary;
    z-index: 2;
    -webkit-transition: width 0.3s -0.1s;
    -moz-transition: width 0.3s -0.1s;
    transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
    width: 100%;
    -webkit-transition: width 3s;
    -moz-transition: width 3s;
    transition: width 3s;
}

.cd-headline.loading-bar b {
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
    margin-bottom: 18px;
}

.cd-headline.loading-bar b.is-visible {
    opacity: 1;
    top: 0;
}

/*!---------- BRANDS ----------*/
.brand {
    padding: 10px;
    height: 150px;
}

.brand div {
    margin: 10px;
    border: 1px solid #ddd;
    height: 130px;
}

.brand div img {
    max-width: 60%;
    max-height: 100px;
    height: auto;
    position: relative;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    top: 50%;
    z-index: 1;
    padding: 5px;
}


/*-------- ABOUT PAGE ---------*/
.content {
    position: relative;
    padding: 2em;
}

.line::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background: @color-primary;
    top: calc(100% + 10px);
    left: 0;
}

.grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5vw;
}

.about-image {
    display: flex;
    flex-wrap: wrap;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.about-item {
    padding: 0vh 8vw 20vh;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    grid-row: span 2;
    grid-column: 1;
}

.about-item:nth-child(odd) {
    grid-column: 2;
}

@media (max-width: 992px) {
    .about-item {
        padding: 0vh 8vw 5vh;
    }
}

.about-item-name {
    margin-top: -10px;
    font-size: 5vw;
    position: relative;
    line-height: 0.8;
    align-self: end;
    justify-self: end;
    grid-area: 1 / 1 / 3 / 3;
    text-transform: uppercase;
    font-family: @heading-font;
    font-weight: 500;
}

.about-item-title {
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    font-size: 1.5vw;
    padding: -2.5vw 0 0;
    letter-spacing: 0.05em;
    white-space: nowrap;
    margin: 0;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    grid-area: 1 / 2 / 2 / 3;
    .transition(.3s);
}

.grid .about-image:hover ~ .about-item-title {
    font-size: 1.9vw;
}

.grid .about-image:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
}

.grid .about-image {
    grid-area: 1 / 1 / 3 / 2;
    width: 25vw;
    height: calc((25vw) * 1.3157);
    position: relative;
    justify-self: end;
}

@media screen and (max-width: 60em) {
    .grid {
        grid-template-columns: auto;
        width: 60vw;
    }

    .grid .about-image {
        width: 60vw;
        height: calc((60vw) * 1.3157);
    }

    .grid .about-image:hover ~ .about-item-title {
        font-size: 1.3em;
    }

    .about-item:nth-child(odd) {
        grid-column: 1;
    }

    .about-item-title {
        font-size: 1.3em;
    }

    .about-item-name {
        font-size: 3em;
    }
}

/*-------- CASE OVERVIEW ---------*/

.case-overview-header {
    height: 50vh;
    background-color: #002776;
}

.case-overview-header-text {
    position: relative;
    bottom: 0;
}

.case-overview-header-service {
    padding: 32px 15px;

    span {
        font-family: ' benedenboven-light ';
        font-size: 11px;
        line-height: 18px;
    }

    span:after {
        content: " | ";
    }
}

.case-shadow {
    height: 150px;
    width: 150px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -140px;
    left: 0;
    animation: MoveShadow 5s ease infinite;
}

@keyframes MoveShadow {
    0%, 20%, 80%, 100% {
        transform: scale(1.0);
    }
    40%, 60% {
        transform: scale(0.8);
    }
}

.case-padding-12 {
    padding: 0 3% 0 3%;
}

.case-padding-8 {
    padding: 0 4.5% 0 4.5%;
}

.case-padding-6 {
    padding: 0 6% 0 6%;
}

.case-padding-4 {
    padding: 0 9.5% 0 9.5%;
}

@media (max-width: 992px) {
    .case-padding-12, .case-padding-8, .case-padding-6, .case-padding-4 {
        padding: 0;
    }
}

.case-header {
    height: 50vh;
    background-color: red;
    background-size: contain;
    border: 1px solid red;
}

.case-norm {
    height: 50vh;
    color: #fff;
}

.case-balloon {
    height: 150px;
    width: 150px;
    border-radius: 150px;
    border: 2px solid #fff;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: MoveUpDown 5s ease infinite;
    transition: transform .2s;
    background-color: #fff;
}

.case-balloon:hover {
    transform: scale(1.2);
}

@keyframes MoveUpDown {
    0%, 20%, 80%, 100% {
        bottom: 0;
    }
    40%, 60% {
        bottom: 20px;
    }
}

.delay1 {
    animation-delay: 1s;
}

.delay2 {
    animation-delay: 2s;
}

/*-------- CASE OVERVIEW NEW 09-2019 ---------*/

figure div.overlay-case {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
}

figure:hover div.overlay-case {
    opacity: 0.95;
}

.company {
    color: #fff;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

    img {
        width: 100%;
    }
}

.case {
    min-height: 400px;
    display: block;
    margin: 10px 0;
    transition: transform 0.4s;

    &:hover {
        transform: scale(1.1);
        z-index: 999;
    }

    .case-overview-logo {
        display: block;
        min-height: 400px;

        img {
            max-height: 170px;
            max-width: 250px;
            margin: 0 auto;
        }
    }


}

/*!---------- CASE DETAIL PAGE ----------*/

.case-head-img {
    max-width: 350px;
}

.case-logo {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    float: left;
    position: relative;
    background-color: #fff;
    display: block;

    div {
        padding: 40px;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: block;
    }
}

@media (max-width: 1200px) {
    .case-logo div {
        padding: 25px;
    }
}

@media (max-width: 990px) {
    .case-logo div {
        padding: 15px;
    }
}

.case-logo:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.case-info {
    float: left;
    position: relative;

    a:before {
        color: #fff;
    }
}

.service-logo {
    width: 28%;
    height: auto;
    display: inline-block;
    margin-right: 5%;
    padding-bottom: 20px;
}

@media (max-width: 1200px) {
    .service-logo {
        width: 100%;
        margin-right: 0;

        img {
            width: 40%;
            display: block;
            margin: 0 auto;
        }
    }
}

.mix-blend-mode-difference {
    mix-blend-mode: difference;
}

.case-detail-logo svg path {
    fill: #fff;
    stroke: #fff;
    .transition(.3s);
}

.case-detail-logo svg polygon {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.case-detail-logo svg rect {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.case-detail-logo svg circle {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.case-detail-logo svg ellipse {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.case-detail-logo svg polyline {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.case-detail-logo svg line {
    fill: #fff;
    stroke: #000;
    .transition(.3s);
}

.img-edge {
    height: 400px;
    float: right;
}

.case-service {
    color: #fff;

    h3 {
        color: #fff;
    }

    a {
        color: #fff;
        display: inline-block;
        margin: 5px;
        border-color: #fff;
    }
}

.fr {
    float: right;
}

.bn {
    border: none !important;
}

.powered-title {
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    padding-bottom: 24px;
}

@media (max-width: 990px) {
    .powered-title {
        font-size: 55px;
        line-height: 60px;
    }
}

.powered {
    padding: 50px;
    transition: all 1.0s !important;
}

.powered:hover {
    div .powered-logo {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        .bounce
    }
}

.powered-logo {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    float: left;
    position: relative;
    display: inline-block;
    border: 1px solid #ddd;

    div {
        padding: 25px;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: block;
    }
}

.powered-info {
    display: inline-block;
    padding-right: 0;
    padding-left: 40px;
}

.powered-info a {
    color: @color-primary;
}

.back-grey {
    background: linear-gradient(0.25turn, @bg-secondary, @color-white, @bg-secondary);
    background-size: 400% 400%;
    .background-animation
}

.back-grey2 {
    background: linear-gradient(0.25turn, @bg-secondary, @color-white, @bg-secondary, @color-white,);
    background-size: 400% 400%;
    .background-animation
}

.back-grey3 {
    background: linear-gradient(0.25turn, @color-white, @bg-secondary, @color-white, @bg-secondary);
    background-size: 400% 400%;
    .background-animation
}

.background-animation {
    -webkit-animation: AnimationName 20s ease infinite;
    -moz-animation: AnimationName 20s ease infinite;
    animation: AnimationName 20s ease infinite;

    @-webkit-keyframes AnimationName {
        0% {
            background-position: 0% 50%
        }
        50% {
            background-position: 100% 50%
        }
        100% {
            background-position: 0% 50%
        }
    }
    @-moz-keyframes AnimationName {
        0% {
            background-position: 0% 50%
        }
        50% {
            background-position: 100% 50%
        }
        100% {
            background-position: 0% 50%
        }
    }
    @keyframes AnimationName {
        0% {
            background-position: 0% 50%
        }
        50% {
            background-position: 100% 50%
        }
        100% {
            background-position: 0% 50%
        }
    }
}


/*!---------- MODALS ----------*/
.modal-content {
    border-radius: 0;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 1000px;
    }
}

.modal-product {
    max-width: 600px;
}

.modal-product .modal-body {
    padding: 2rem;
}

.modal-header {
    border-bottom: none;
}


/*!---------- BUTTONS ----------*/
.bg-dark .btn {
    color: #fff;
}

.bg-dark .btn:hover {
    color: @color-primary;
}

.bg-dark .btn:before {
    color: @color-primary;
}

.bg-primary .btn:hover {
    color: #000;
}

.bg-primary .btn:before {
    color: #000;
}

.bg-primary .btn-rounded {
    color: @color-white;
    border-color: @color-white;
}

.bg-dark .btn-rounded {
    color: @color-white;
    border-color: @color-white;
}

.image-bg .btn-rounded {
    color: @color-dark;
    border-color: @color-white;
    border-width: 2px;
    font-weight: bold;
    background-color: @color-white;
}

.image-bg .btn-rounded:hover {
    color: @color-primary;
    border-color: @color-primary;
    border-width: 2px;
    font-weight: bold;
    background-color: @color-white;
}

.grid-masonry-item-img .btn-rounded {
    color: @color-white;
    border-color: @color-white;
}

.bg-primary .btn-rounded:hover {
    color: @color-body;
    border-color: @color-white;
    background-color: @color-white;
}

.bg-dark .btn-rounded:hover {
    color: @color-white;
    border-color: @color-primary;
    background-color: @color-primary;
}

.bg-white .btn-rounded:hover {
    color: @color-white;
    border-color: @color-primary;
    background-color: @color-primary;
}

.grid-masonry-item-img .btn-rounded:hover {
    color: @color-white;
    border-color: @color-primary;
    background-color: @color-primary;
}

.btn.color-primary:hover {
    color: #000 !important;
}

.btn.color-primary:hover:before {
    color: @color-primary !important;
}

.btn {
    color: @bg-dark;
    font-family: @heading-font;
}

.btn-rounded {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid @bg-dark;
    border-radius: 30px;
    color: @color-body;
    font-family: @heading-font;
    font-size: 15px;
    .thin;
}

.btn-rounded:hover {
    border: 1px solid @color-primary;
}

.btn-rounded.active {
    border-color: @color-primary;
    color: #fff;
    background: @color-primary;
}

.btn-rounded.color-primary {
    border-color: @color-primary;
}

.btn-rounded.color-primary:hover {
    border-color: @bg-dark;
    color: @bg-dark;
}

@media (max-width: 990px) {
    .btn-rounded {
        font-size: 12px;
        margin-bottom: 16px;
    }
}

.btn-lg {
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.hvr-icon-spin {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    padding-left: 2.2em
}

.hvr-icon-spin:before {
    color: #000;
    content: "\f067";
    font-family: @font-awesome;
    font-weight: 900;
    position: absolute;

    left: 1em;
    padding: 0 1px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out
}


.hvr-icon-spin:active:before,
.hvr-icon-spin:focus:before,
.hvr-icon-spin:hover:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.image-bg .hvr-icon-spin:before {
    color: @color-white;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

/*!---------- HOMEPAGE ----------*/
.client-home {
    /*visibility: hidden;*/
    display: none;
    position: relative;
    margin-bottom: 120px;
}

.client-home img {
    max-width: 50%;
    max-height: 150px;
    height: auto;
    position: relative;
    z-index: 1;
    padding: 5px;
}

@media (max-width: 767px) {
    .client-home img {
        max-width: 80%;
        margin-bottom: 100px;
    }

    .client-hidden {
        display: none;
    }
}

.service-category-btn {
    margin-top: 8px;
    display: inline;
    padding: 0;
}

.service-category-btn li {
    list-style: none;
    display: inline-block;
    margin-bottom: 8px;
    padding: 5px;
}

/*!---------- NARROWCASTING PAGE ----------*/

.page-title-narrowcasting {
    background-color: #101010;
    height: 100vh;
    min-height: 600px;

    .header-image-narrowcasting {
        background-color: #101010;
        background-blend-mode: lighten;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50%;
        @media (max-width:767px){
            width: 150vw;
        }
    }

    .container {
        position: relative;
        transform: translate3d(0, -50%, 0);
        -webkit-transform: translate3d(0, -50%, 0);
        top: 50%;
        z-index: 2;

        p {
            color: @color-white;
        }
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(60px);
    }
}

svg #wheel {
    animation: scroll ease 2s infinite;
}

.mouse-down {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;
    z-index: 99;
}

.p20 {
    padding: 20px;
}

.p40 {
    padding: 40px;
}

.pt40 {
    padding-top: 40px;
}

h3.stroke {
    padding: 5px 15px;
    background-color: @color-primary;
    color: @color-white;
    display: inline-block;
}

.narrowcasting-target {
    padding: 50px 0;
    min-height: 500px;
}

.narrowcasting-indicator {
    top: 0;
    bottom: auto;

    li {
        width: 15px;
        height: 15px;
        background-color: @color-dark;
    }
}

.carousel-indicators > li {
    border-radius: 50%;
}


/*!---------- ATOM PAGE ----------*/

/*!---------- WERKEN BIJ PAGE ----------*/


/*!---------- CONTACT PAGE ----------*/

.map-holder {
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.map-holder .inner {
    border: none;
    position: absolute;
    width: 100%;
    height: 180%;
    top: -40%;
    left: 0;
    z-index: 1;
}

.map-holder:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.map-holder.interact:before {
    width: 0;
}


/*!---------- JOBS PAGE / JOBS DETAIL / VACATURES / VACANCIES  ----------*/

.vacancy {
    border-bottom: 1px solid #000;
}

.vacancies-header {
    border: 30px solid #fff;
}

.vacancy i {
    font-size: 24px;
}

.vacancy h5 {
    margin-right: 48px;
    color: @color-gray;
}

.vacancy:hover {
    background-color: @bg-dark;
    color: @color-white;
}

.vacancy:hover h3 {
    color: @color-white;
}

.jobs-figure {
    height: 400px;
    position: absolute;
    z-index: 3;
}

.jobs-line {
    fill: none;
    stroke: #000;
    mix-blend-mode: difference;
}

.jobs-dot {
    fill: @color-primary;
}

.form-control {
    border-radius: 0px;
    border: 2px solid #e6e6e6;
    padding: 12px 50px 12px 20px;
    height: auto;
}

.form-control:focus {
    border-color: #000;
    box-shadow: none;
}

/*!---------- USP HOME ----------*/

.usp {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    padding-top: 48px;
    padding-bottom: 48px;
}

.usp svg {
    width: 90px;
    height: 100px;
}

/*!---------- IMG BLOCKS SERVICES ----------*/

.services-img {
    right: -10em;
    position: absolute;
}

.services-list {
    padding: 0;
}

.services-list li {
    list-style: none;
    margin-bottom: 10px;
}

.services-list li:hover a {
    margin-left: 4px;
}

/*!---------- FOOTER ----------*/

footer {
    background: #fff;
}

footer .cta {
    color: #fff;
    background: @color-primary;
    padding: 32px;
    height: 240px;
    margin-top: -120px;
}

.footer-adress a {
    color: @color-dark;
    font-family: @body-font !important;
    font-weight: 300;
}

.contact-page-list {
    list-style: none;
    padding-left: 0px;
}

.social-list {
    margin: 0;
}

.round {
    display: inline-block;
    height: 60px;
    width: 60px;
    line-height: 56px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #FFF;
    text-align: center;
    background: none;
    border: 2px solid @color-primary;
}

.round:hover {
    background: @color-primary;
    .transition (.3s);
}

.copy {
    font-family: @heading-font;
    .thin;
    font-size: 14px;
}

a:focus,
a:hover,
a:active {
    color: @color-primary;
}


// Components
@import 'components/blocks-home';
@import 'components/badges';
@import 'components/fs-form';
@import 'components/spacing';
@import 'components/projects';