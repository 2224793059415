:root {
    --background-next: rgba(233, 30, 99, 0.7);
    --project-main-colour: rgba(233, 30, 99, 0.7);
}

.project-section {
    z-index: 1;
    padding: 0;
    background: #000;

    .project-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        background: linear-gradient(to right bottom, var(--project-main-colour) 0%, rgba(0, 0, 0, 1) 100%) !important;

        .project-text-container {
            width: 100%;
            color: #fff;
            position: sticky;
            height: 100vh;
            top: 0;

            display: flex;
            align-items: center;

            .simple-breadcrumb {
                position: absolute;
                bottom: 30px;

                a {
                    color: #fff;

                    &:hover {
                        color: @color-primary;
                    }
                }

                span {
                    color: @color-primary;

                    &.spacer {
                        margin: 0 15px;
                    }
                }
            }

            .inner-container {
                padding: 5rem;

                .breadcrumb {
                    display: inline-block;
                }

                .logos {
                    display: flex;
                    align-items: center;
                    gap: 50px;

                    .anim-logo {
                        clip-path: polygon(0 0, 0 0, 0 50%, 0 100%, 0 100%, 0% 50%);

                        transition: clip-path 0.5s ease-out;

                        &.init {
                            clip-path: polygon(0 0, 75% 0, 200% 50%, 75% 100%, 0 100%, 0% 50%);
                        }
                    }

                    span {
                        font-size: 40px;
                        color: @color-primary;

                        i {
                            -webkit-animation: glow 1s ease-in-out infinite alternate;
                            -moz-animation: glow 1s ease-in-out infinite alternate;
                            animation: glow 1s ease-in-out infinite alternate;
                        }
                    }
                }
            }

            a.btn {
                font-family: 'benedenboven';

                &:hover {
                    color: #fff !important;
                    border-color: @color-primary !important;

                    box-shadow: 0 5px 15px @color-primary;
                }
            }

            h2 {
                margin: 0;
                padding: 0;
                font-weight: normal;
                font-size: 20px;
                font-family: @body-font;
            }
        }

        .project-image-container {
            width: 100%;

            img:not(:last-child) {
                margin-bottom: 3rem;
            }
        }

        @media (max-width: 2000px) {
            .project-text-container {
                width: 90%;

                .inner-container {
                    padding: 4rem;
                }

                .logos {
                    img {
                        height: 80px;
                    }
                }

                h1 {
                    font-size: 45px;
                    line-height: 70px;
                }

                h4 {
                    font-size: 18px;
                }

                .btn {
                    padding: 3px 18px;
                    font-size: 13px;
                }
            }
        }

        @media (max-width: 1600px) {
            .project-text-container h1 {
                font-size: 40px;
                line-height: 50px;
            }

            .inner-container {
                padding: 3rem;
            }
        }

        @media (max-width: 1400px) {
            .project-text-container {
                width: 80%;

                .inner-container {
                    padding: 2rem;
                }

                .logos {
                    gap: 25px !important;

                    span {
                        font-size: 20px !important;
                    }

                    img {
                        height: 60px;
                    }
                }

                h1 {
                    font-size: 30px;
                    line-height: 40px;
                }

                h4 {
                    font-size: 14px;
                }

                .btn {
                    padding: 3px 14px;
                    font-size: 12px;
                }
            }
        }

        @media (max-width: 1000px) {
            flex-direction: column;

            .simple-breadcrumb {
                display: none;
            }

            .project-text-container {
                height: auto;
                width: 100%;
                padding-top: 30px;
                padding-bottom: 100px;

                .scrolldown {
                    right: auto;
                    left: 50%;
                    opacity: 1 !important;
                    transform: translateX(-50%) scale(0.8, 0.8);
                }
            }

            @media (max-width: 400px) {
                .logos {
                    gap: 20px !important;

                    span {
                        font-size: 15px !important;
                    }

                    img {
                        height: 40px !important;
                    }
                }

                .btn {
                    padding: 3px 10px !important;
                    font-size: 11px !important;
                }

                h4 {
                    font-size: 13px !important;
                    margin-bottom: 10px !important;
                }
            }

            .project-image-container {
                z-index: 2;
                background: #000 linear-gradient(to right bottom, var(--project-main-colour) 0%, rgba(0, 0, 0, 1) 100%) !important;
            }
        }
    }
}

.next-project {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    padding: 0;
    position: sticky;
    cursor: pointer;

    .next-project-preview {
        display: flex;

        .img-container {
            height: 500px;
            width: 98%;
            z-index: 1;
            overflow: hidden;
            transition: all .5s ease-out;
            clip-path: polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%, 0% 50%);

            &:before {
                .overlay-params(1, linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%));
            }

            .next-project-content {
                z-index: 2;
                margin-left: 5rem;
                height: 100%;
                position: absolute;
                top: 0;

                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 40px;
                    padding-right: 20px;
                }

                h4 {
                    margin: 0;
                    width: fit-content;
                    position: relative;
                }

                .chevrons {
                    top: 10px;
                    right: 0px;
                    margin-top: 0;
                    opacity: 0;
                    position: absolute;
                    transform: rotate(-90deg) scale(1.5, 1.5);
                    transition: all 0.3s;
                }

                @media (max-width: 2000px) {
                    margin-left: 4rem;
                }

                @media (max-width: 1400px) {
                    margin-left: 2rem;
                }

                @media (max-width: 1000px) {
                    h3 {
                        font-size: 30px;
                    }

                    h4 {
                        font-size: 15px;
                    }

                    .chevrons {
                        top: 8px;
                        transform: rotate(-90deg) scale(0.8, 0.8);
                    }
                }

                @media (max-width: 600px) {
                    h3 {
                        font-size: 25px;
                    }
                }
            }

            @media (max-width: 600px) {
                clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 0% 50%);
            }

            img {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                transform: scale(1.1, 1.1);

                transition: all 1s ease-out;
            }
        }

        .btn-container {
            width: 50%;
            z-index: 0;
            padding-right: 30px;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            text-align: right;

            background: linear-gradient(to left bottom, var(--background-next) 0%, rgba(0, 0, 0, 1) 100%) !important;

            @media (max-width: 600px) {
                width: 100%;
            }

            img {
                max-width: 200px;
                width: 200px;

                @media (max-width: 820px) {
                    width: 150px;
                }

                clip-path: polygon(0 0, 0 0, 0 50%, 0 100%, 0 100%, 0% 50%);
                transition: all 0.3s ease-out;
            }
        }

        &:hover, &:active, &:focus {
            .img-container {
                width: 90%;

                @media (max-width: 2400px) {
                    width: 85%;
                }

                @media (max-width: 1600px) {
                    width: 80%;
                }

                @media (max-width: 1250px) {
                    width: 75%;
                }

                @media (max-width: 1000px) {
                    width: 70%;
                }

                @media (max-width: 820px) {
                    width: 60%;
                }

                @media (max-width: 600px) {
                    width: 50%;
                }

                img {
                    transform: scale(1, 1);
                }

                .chevrons {
                    right: -30px;
                    opacity: 1;
                }
            }

            .btn-container {
                img {
                    clip-path: polygon(0 0, 75% 0, 200% 50%, 75% 100%, 0 100%, 0% 50%);
                }

                h5 {
                    right: 100px;
                    opacity: 1;
                }
            }
        }
    }

    &.project-overview-banner {
        position: relative;

        .img-container {
            width: calc(100% - 250px);

            transition: all 0.3s ease-out;

            img {
                transition: all .5s ease-out;
                transform: scale(1, 1);
            }

            .next-project-content {
                width: calc(100% - 250px);
            }
        }

        @media (max-width: 820px) {
            .img-container {
                height: 300px;
                width: calc(100% - 200px);
            }
        }

        .btn-container {
            width: 100%;

            img {
                clip-path: none;
            }
        }

        .next-project-preview {
            &:hover, &:active, &:focus {
                .img-container {
                    width: 100%;
                    clip-path: polygon(0 0, 100% 0%, 100% 50%, 100% 100%, 0 100%, 0% 50%);

                    img {
                    }
                }

                .btn-container {

                    img {
                        clip-path: none;
                    }
                }
            }
        }
    }

    &.project-overview-banner-small {

        .img-container {
            height: 300px;
            width: calc(100% - 200px);
        }

        .next-project-content {
            margin-left: 30px !important;

            h4 {
                font-size: 20px !important;
                padding-right: 20px;
            }

            h3 {
                font-size: 30px !important;
            }
        }

        .btn-container {
            img {
                width: 150px;
            }
        }
    }
}

.anim-text .word {
    display: inline-block;
    position: relative;
    overflow: hidden;

    > span {
        opacity: 0;
        display: inline-block;
    }
}

.anim-element {
    opacity: 0;
}

.scrolldown {
    --color: white;
    --sizeX: 30px;
    --sizeY: 50px;
    position: absolute;
    width: var(--sizeX);
    height: var(--sizeY);
    margin-left: var(sizeX / 2);
    border: calc(var(--sizeX) / 10) solid var(--color);
    border-radius: 50px;
    box-sizing: border-box;
    right: 30px;
    bottom: 30px;

    transition: opacity 0.3s ease-out;
}

.scrolldown::before {
    content: "";
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: @color-primary;
    border-radius: 100%;
    animation: scrolldown-anim 2s infinite;
    box-sizing: border-box;
    box-shadow: 0px -5px 3px 1px @color-primary;
}

@keyframes scrolldown-anim {
    0% {
        opacity: 0;
        height: 6px;
    }
    40% {
        opacity: 1;
        height: 10px;
    }
    80% {
        transform: translate(0, 20px);
        height: 10px;
        opacity: 0;
    }
    100% {
        height: 3px;
        opacity: 0;
    }
}

.chevrons {
    padding: 6px 0 0 0;
    margin-left: -3px;
    margin-top: 48px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chevrondown {
    margin-top: -6px;
    position: relative;
    border: solid @color-primary;
    border-width: 0 3px 3px 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
    animation: pulse 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
    animation: pulse 500ms ease infinite alternate 250ms;
}

@keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 0px @color-primary, 0 0 10px @color-primary, 0 0 20px @color-primary;
    }
    to {
        text-shadow: 0 0 20px @color-primary, 0 0 30px @color-primary, 0 0 40px @color-primary;
    }
}