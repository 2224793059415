.badge {

}

.badge-vacancy-home {
    text-align: center;
    display: inline-block;
    color: @color-white;
    background: @color-primary;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 13px;
    line-height: 20px;
    padding: 0;
    font-family: @heading-font;
}

.badge-vacancy-nav {
    text-align: center;
    display: inline-block;
    color: @color-white;
    background: @color-primary;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 18px;
    line-height: 23px;
    padding: 0;
    font-family: @heading-font;
    margin-left: 5px;
}
