.coffee-vid {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto
}

.gridzy-walloffame-home {
    max-height: 490px;
    overflow-y: hidden;
}

.link-walloffame-home {
    z-index: 20;
    width: 33.3333%;
    position: absolute;
    top: 400px;
}

.block1 {
    height: 490px;
    width: 33.3333%;
    padding: 0;
    overflow-y: hidden;
    position: relative;
}

.block1 h3 {
    color: #fff;
    max-width: 400px;
    padding-left: 24px;
    padding-bottom: 24px;
    z-index: 10;
    position: absolute;
    left: 0;
    bottom: 0;
}

.block2 {
    height: 280px;
    width: 33.3333%;
    padding: 24px;
}

.block2 img {
    max-width: 50%;
    max-height: 40%;
    margin-bottom: 24px;
    padding: 5px;
}

.block3 {
    height: 650px;
    width: 33.3333%;
    padding: 24px;
}

.block3 img {
    max-width: 80%;
    max-height: 30%;
    margin-bottom: 32px;
    padding: 5px;
}

.block4 {
    height: 410px;
    width: 66.6666%;
    margin-top: -160px;
    padding: 24px;
}

.block4 h3 {
    color: #fff;
    max-width: 400px;
    .v-align-bottom;
    z-index: 10;

}

.block5 {
    height: 210px;
    margin-top: -370px;
    margin-left: -33.3333%;
    width: 33.3333%;
    padding: 24px;
}

.block6 {
    height: 250px;
    width: 33.3333%;
    padding: 24px;
}

.block6 h3 {
    font-size: 96px;
    line-height: 96px;
}

@media (max-width: 1100px) {

    .coffee-vid {
        display: none;
    }

    .gridzy-walloffame-home {
        max-height: 440px;
    }

    .block1 {
        height: 440px;
        width: 50%;
    }

    .block1 h3 {
        padding-left: 12px;
        padding-bottom: 12px;
    }

    .block2 {
        height: 230px;
        width: 50%;
        padding: 12px;
    }

    .block3 {
        height: 480px;
        width: 50%;
        padding: 12px;
    }

    .block4 {
        height: 190px;
        width: 50%;
        margin-top: -270px;
        padding: 12px;
        z-index: 0;
    }

    .block5 {
        height: 210px;
        margin-top: -80px;
        margin-left: 0%;
        width: 50%;
        padding: 12px;
    }

    .block6 {
        height: 130px;
        width: 50%;
        padding: 12px;
    }

    .block6 h3 {
        font-size: 52px;
        line-height: 54px;
    }
}

@media (min-width: 1400px) {
    .gridzy-walloffame-home {
        max-height: 690px;
    }

    .link-walloffame-home {
        top: 600px;
    }

    .block1 h3 {
        max-width: 200px;
        pointer-events: none;
    }

    .block1 {
        height: 690px;
    }

    .block1-test h3 {
        max-width: 200px;
    }

    .block2 {
        height: 380px;
        padding: 24px;
    }

    .block2 img {
        max-width: 50%;
        max-height: 40%;
        margin-bottom: 24px;
        padding: 5px;
    }

    .block3 {
        height: 750px;
        padding: 24px;
    }

    .block3 img {
        max-width: 80%;
        max-height: 30%;
        margin-bottom: 32px;
        padding: 5px;
    }

    .block4 {
        height: 410px;
        margin-top: -60px;
        padding: 24px;
    }

    .block4 h3 {
        color: #fff;
        max-width: 400px;
        .v-align-bottom;
    }

    .block5 {
        height: 310px;
        margin-top: -370px;
        margin-left: -33.3333%;
        width: 33.3333%;
        padding: 24px;
    }

    .block6 {
        height: 350px;
        width: 33.3333%;
        padding: 24px;
    }

    .block6 h3 {
        font-size: 96px;
        line-height: 96px;
    }
}

.relative {
    position: relative;
    width:100%;
    height:auto;
}

.nose {
    position: absolute;
    top:27%;
    left:37%;
    width:5%;
    height:4%;
    z-index: 9;
}
